import React, {useContext} from "react";
import DocumentsTable from "../components/documents-table";
import DocumentsUpload from "../components/documents-upload";
import {UserContext} from "../contexts";

function DocumentsPage() {
  const user = useContext(UserContext);

  return (
    <React.Fragment>
      {user?.user?.isAdmin && <DocumentsUpload />}

      <DocumentsTable isAdmin={!!user?.user?.isAdmin} />
    </React.Fragment>
  );
}

export default DocumentsPage;

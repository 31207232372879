import {Breadcrumbs} from "@mui/material";
import {MdChevronRight, MdClose, MdFolder} from "react-icons/md";
import {useNavigate} from "react-router-dom";
import {v4 as uuid} from "uuid";
import {IFolderPath} from "../utils/interfaces/documents";
import React, {useState} from "react";

function BreadCrumbButton({text, id, onClick}: {text: string; id?: string; onClick?: () => void}) {
  const keyId = uuid();
  return (
    <div className="relative group">
      <button
        className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 bg-gray-300 rounded-md py-1 px-2 select-none max-w-[90px]"
        key={keyId}
        id={id}
        onClick={onClick}
      >
        <span className="truncate">{text}</span>
      </button>
      {text !== "..." && (
        <div className="absolute z-10 inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm tooltip dark:bg-gray-700 bottom-[110%] left-0 w-max opacity-0 group-hover:opacity-100">
          {text}
        </div>
      )}
    </div>
  );
}

function TableBreadCrumbs({folderPath}: {folderPath: IFolderPath[]}) {
  const navigate = useNavigate();

  const [isDropdownVisible, setDropdownVisible] = useState(false);

  function createBreadCrumbs() {
    if (!folderPath.length) return null;

    if (folderPath.length > 2) {
      return [
        <BreadCrumbButton text="..." onClick={() => setDropdownVisible(true)} key={uuid()} />,
        ...folderPath
          .slice(-2)
          .map((path: IFolderPath) => <BreadCrumbButton text={path.name} onClick={() => navigate(`/documents/${path.folderId}`)} key={uuid()} />),
      ];
    }

    return [
      <BreadCrumbButton text="Home" onClick={() => navigate("/documents")} key={uuid()} />,
      ...folderPath.map((path: IFolderPath) => (
        <BreadCrumbButton text={path.name} onClick={() => navigate(`/documents/${path.folderId}`)} key={uuid()} />
      )),
    ];
  }

  return (
    <React.Fragment>
      <Breadcrumbs separator={<MdChevronRight className="w-6 h-6 text-gray-400" fontSize="small" />} aria-label="breadcrumb">
        {createBreadCrumbs()}
      </Breadcrumbs>

      <div
        className={`bg-black/20 top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] md:h-full ${
          isDropdownVisible ? "fixed" : "hidden"
        }`}
        onClick={() => setDropdownVisible(false)}
      ></div>

      <div
        className={`z-51 bg-white rounded-lg shadow w-60 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50 ${
          isDropdownVisible ? "fixed" : "hidden"
        }`}
      >
        <div className="flex items-center justify-between">
          <button
            type="button"
            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
            onClick={() => setDropdownVisible(false)}
          >
            <MdClose className="w-5 h-5" />
            <span className="sr-only">Close modal</span>
          </button>
        </div>
        <ul className="py-2 overflow-y-auto text-gray-700">
          <li
            key={uuid()}
            onClick={() => {
              setDropdownVisible(false);
              navigate(`/documents`);
            }}
          >
            <span className="flex items-center px-4 py-2 hover:bg-gray-100 hover:cursor-pointer">
              <MdFolder size={20} color="#4f3f8f" className="mr-2" />
              Home
            </span>
          </li>
          {folderPath.slice(0, -2).map((path: IFolderPath) => (
            <li
              key={uuid()}
              onClick={() => {
                setDropdownVisible(false);
                navigate(`/documents/${path.folderId}`);
              }}
            >
              <span className="flex items-center px-4 py-2 hover:bg-gray-100 hover:cursor-pointer">
                <MdFolder size={20} color="#4f3f8f" className="mr-2" />
                {path.name}
              </span>
            </li>
          ))}
        </ul>
      </div>
    </React.Fragment>
  );
}

export default TableBreadCrumbs;

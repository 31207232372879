import {MdDownload} from "react-icons/md";
import {FileTypes} from "../../utils/enums/file-types.enum";
import getUUID from "../../utils/helpers/getUuid";
import {IDocument} from "../../utils/interfaces/documents";
import CircularLoader from "../circular-loader";
import {useState} from "react";
import {saveAs} from "file-saver";

function DownloadFileButton({document}: {document: IDocument}) {
  const [isDownloadLoading, setDownloadLoading] = useState(false);

  function downloadFile(document: IDocument) {
    setDownloadLoading(true);
    saveAs(document.fileUrl, document.originalName);
    setDownloadLoading(false);
  }

  if (document.type !== FileTypes.folder) {
    return (
      <button
        type="button"
        className="text-blue-700 border border-blue-700 hover:bg-blue-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center items-center inline-flex ml-2"
        onClick={() => downloadFile(document)}
        disabled={isDownloadLoading}
        key={getUUID()}
      >
        {isDownloadLoading ? <CircularLoader /> : <MdDownload className="w-5 h-5" />}
      </button>
    );
  }

  return null;
}

export default DownloadFileButton;

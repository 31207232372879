import {AiOutlineCloudUpload} from "react-icons/ai";
import React, {useRef, useState} from "react";
import Chip from "../components/chip";
import {v4 as uuid} from "uuid";
import CircularLoader from "./circular-loader";
import * as _ from "lodash";
import {IFiles} from "../utils/interfaces/files";
import DocumentsApi from "../apis/DocumentsApi";
import {useDocumentListState} from "../store/documentListState";

const fileToDataUri = (file: File, id: string) =>
  new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      resolve({result: event.target?.result, id});
    };
    reader.readAsArrayBuffer(file);
  });

function DocumentsUpload() {
  const documentListState = useDocumentListState();
  const fileRef = useRef<HTMLInputElement>(null);
  const [files, setFiles] = useState<IFiles[]>([]);
  const [loading, setLoading] = useState(false);
  const documentsApi = new DocumentsApi();

  const submit = async (data: any) => {
    setLoading(() => true);
    data.preventDefault();

    const insertedFiles = await documentsApi.insertFiles({files, parentFolderId: documentListState.selectedFolder?.id});

    removeSelectedFile(files);

    insertedFiles.forEach((file) => documentListState.add(file));

    setFiles(() => []);
    setLoading(() => false);
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles: IFiles[] = [...Array(event.target.files?.length)].map((_, i) => ({
      buffer: null,
      file: event.target.files?.item(i)!,
      id: uuid(),
    }));

    if (_.intersectionBy(files, selectedFiles, (file) => file.file?.name).length) {
      return;
    }

    Promise.all(selectedFiles.map((file) => fileToDataUri(file.file!, file.id))).then((response) => {
      const bufferFiles: IFiles[] = response.map((r: any) => {
        const file = selectedFiles.find((file) => file.id === r.id)!;
        return {
          ...file,
          buffer: r.result,
        };
      });

      setFiles((prev) => [...prev, ...bufferFiles]);
      if (fileRef.current) {
        fileRef.current.value = "";
      }
    });
  };

  function removeSelectedFile(removeFiles: IFiles[]) {
    setFiles((prev) => {
      const updatedFiles = _.differenceBy(prev, removeFiles, "id");
      if (updatedFiles.length === 0) setLoading(false);
      return updatedFiles;
    });
  }

  return (
    <div className="flex justify-center flex-col px-10 pt-10 w-full max-w-[600px] mx-auto">
      <form className="relative" onSubmit={submit}>
        <div className="flex items-center justify-center ">
          <label
            htmlFor="dropzone-file"
            className="flex flex-col items-center justify-center w-full h-32 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 "
          >
            <div className="flex flex-col items-center justify-center pt-5 pb-6">
              <AiOutlineCloudUpload className="w-10 h-10 mb-3 text-gray-400" />
              <p className="mb-2 text-sm text-gray-500 select-none">
                <span className="font-semibold">Click to upload</span> or drag and drop
              </p>
              <p className="text-xs text-gray-500 ">PNG, JPG, PDF, CSV</p>
            </div>
            <input id="dropzone-file" type="file" className="hidden" onChange={onChange} multiple ref={fileRef} />
          </label>
        </div>

        {!!files.length && (
          <button
            className="bg-red text-white rounded-lg px-2.5 py-2 font-medium mr-4 hidden sm:block absolute bottom-[10px] right-[-5px]"
            type="submit"
            disabled={loading}
          >
            <span className="flex flex-row select-none">
              {loading && <CircularLoader />}
              Upload
            </span>
          </button>
        )}
      </form>
      <div className="mt-5">
        {!!files.length && files.map((file) => <Chip label={file.file?.name!} key={file.id} onRemove={() => removeSelectedFile([file])} />)}
      </div>
    </div>
  );
}

export default DocumentsUpload;

import {MdEdit} from "react-icons/md";
import {IDocument} from "../../utils/interfaces/documents";
import getUUID from "../../utils/helpers/getUuid";
import {useDialogPopupState} from "../../store/dialogPopupState";

function EditFileButton({document, isAdmin}: {isAdmin: boolean; document: IDocument}) {
  const dialogPopupState = useDialogPopupState();

  if (isAdmin) {
    return (
      <button
        type="button"
        className="text-blue-700 border border-blue-700 hover:bg-blue-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center items-center inline-flex ml-2"
        onClick={() => {
          dialogPopupState.show({isUpdateMode: true, docToUpdate: document});
        }}
        key={getUUID()}
      >
        <MdEdit className="w-5 h-5" />
      </button>
    );
  }

  return null;
}

export default EditFileButton;

import BOCW from '../assets/svgs/bocw_act_registration.svg'
import ContractLabourAuditConsultant from '../assets/svgs/contract_labour_audit_consultant.svg'
import ContractLabourLicence from '../assets/svgs/contract_labour_licence.svg'
import ContractLabourRegistration from '../assets/svgs/contract_labour_registration.svg'
import ContractLabourConsultant from '../assets/svgs/contractor_labour_consultant.svg'
import EPFConsultant from '../assets/svgs/epf_consultant.svg'
import ESIConsultant from '../assets/svgs/esi_consultant.svg'
import FactoryAuditConsultant from '../assets/svgs/factory_audit_consultant.svg'
import FactoryLicence from '../assets/svgs/factory_licence.svg'
import FactoryPlanApproval from '../assets/svgs/factory_plan_approval.svg'
import InterStateMigrantRegistration from '../assets/svgs/inter_state_migrant_registration.svg'
import LabourLawConsultant from '../assets/svgs/labour_law_consultant.svg'
import LabourWelfareFundConsultant from '../assets/svgs/labour_welfare_fund_consultant.svg'
import PayrollAuditConsultant from '../assets/svgs/payroll_audit_consultant.svg'
import PayrollConsultant from '../assets/svgs/payroll_consultant.svg'
import ProfessionalTaxConsultancy from '../assets/svgs/professional_tax_consultancy.svg'
import ShopsAndEstablishmentLicence from '../assets/svgs/shops_and_establishment_license.svg'
import StatutoryReturns from '../assets/svgs/statutory_returns.svg'

const services = [
  {
    svg: BOCW,
    name: 'BOCW Act Registration'
  },
  {
    svg: ContractLabourAuditConsultant,
    name: 'Contractor Labour Audit Consultant'
  },
  {
    svg: ContractLabourLicence,
    name: 'Contract Labour Licence'
  },
  {
    svg: ContractLabourRegistration,
    name: 'Contract Labour Registration'
  },
  {
    svg: ContractLabourConsultant,
    name: 'Contract Labour Consultant'
  },
  {
    svg: EPFConsultant,
    name: 'EPF Consultant'
  },
  {
    svg: ESIConsultant,
    name: 'ESI Consultant'
  },
  {
    svg: FactoryAuditConsultant,
    name: 'Factory Audit Consultant'
  },
  {
    svg: FactoryLicence,
    name: 'Factory Licence'
  },
  {
    svg: FactoryPlanApproval,
    name: 'Factory Plan Approval'
  },
  {
    svg: InterStateMigrantRegistration,
    name: 'Inter State Migrant Registration'
  },
  {
    svg: LabourLawConsultant,
    name: 'Labour Law Consultant'
  },
  {
    svg: LabourWelfareFundConsultant,
    name: 'Labour Welfare Fund Consultant'
  },
  {
    svg: PayrollAuditConsultant,
    name: 'Payroll Audit Consultant'
  },
  {
    svg: PayrollConsultant,
    name: 'Payroll Consultant'
  },
  {
    svg: ProfessionalTaxConsultancy,
    name: 'Professional Tax Consultancy'
  },
  {
    svg: ShopsAndEstablishmentLicence,
    name: 'Shops And Establishment Licence'
  },
  {
    svg: StatutoryReturns,
    name: 'Statutory Returns'
  },
]

export default services;
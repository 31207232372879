import { createContext } from "react";
import { User } from 'firebase/auth';

type ContextType = {
  user: {
    user: User | null;
    isAdmin: boolean
  } | null;
};

export const UserContext = createContext<ContextType | null>(null);
import {create} from "zustand";
import {PopupState} from "../utils/interfaces/popup-state";

const loginPopupState = create<PopupState>();

export const useLoginPopupState = loginPopupState((set) => ({
  isHidden: true,
  show: () => set(() => ({isHidden: true})),
  hide: () => set(() => ({isHidden: false})),
}));

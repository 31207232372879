import {Outlet} from "react-router-dom";
import Header from "../components/header";
import {IPage} from "../utils/interfaces/pages";

export const HomeLayout = () => {
  const pages: IPage[] = [
    {label: "Home", href: "/"},
    {label: "Documents", href: "/documents"},
  ];

  return (
    <div>
      <Header pages={pages} />
      <Outlet />
    </div>
  );
};

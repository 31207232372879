import React, {useEffect, useState} from "react";
import {MdCreateNewFolder} from "react-icons/md";
import {useMutation, useQuery} from "react-query";

import TableSkeleton from "./table-skeleton";
import InteractiveDialog from "./interactive-dialog";
import {useParams} from "react-router-dom";
import TableBreadCrumbs from "./table-breadcrumbs";
import TableRow from "./table-row";
import {IDocument} from "../utils/interfaces/documents";
import getUUID from "../utils/helpers/getUuid";
import EmptyData from "../assets/svgs/empty_data.svg";
import {useDialogPopupState} from "../store/dialogPopupState";
import {useDocumentListState} from "../store/documentListState";
import DocumentsApi from "../apis/DocumentsApi";
import SearchBar from "./search-bar";
import {useConfirmationDialogState} from "../store/confirmationDialogState";
import ConfirmationDialog from "./confirmationDialog";

function DocumentsTable({isAdmin}: {isAdmin: boolean}) {
  const documentsApi = new DocumentsApi();

  const [isDeleteLoading, setDeleteLoading] = useState(false);

  const params = useParams<{documentId?: string}>();
  const dialogPopupState = useDialogPopupState();
  const confirmationDialogState = useConfirmationDialogState();
  const documentListState = useDocumentListState();

  const [isNewFolderLoading, setNewFolderLoading] = useState(false);

  const {mutate: deleteDocuments} = useMutation({
    onMutate() {
      setDeleteLoading(true);
    },
    mutationFn(document: IDocument) {
      return documentsApi.deleteFiles({document, allDocuments: documentListState.allDocuments});
    },
    onSuccess(result) {
      documentListState.delete(result);
      setDeleteLoading(false);
      confirmationDialogState.hide();
    },
  });

  useEffect(() => {
    documentListState.navigateToFolder(params.documentId);
    // eslint-disable-next-line
  }, [params]);

  const {isLoading} = useQuery({
    queryKey: ["files"],
    queryFn: () => documentsApi.fetchDocuments(),
    onSuccess(data) {
      documentListState.setDocuments(data, params.documentId);
    },
  });

  const {mutate: createNewFolder} = useMutation({
    onMutate() {
      setNewFolderLoading(true);
    },
    mutationFn(payload: {name: string; parentFolderId?: string}) {
      return documentsApi.createFolder(payload);
    },
    onSuccess(result) {
      setNewFolderLoading(false);
      documentListState.add(result);
      dialogPopupState.hide();
    },
  });

  const {mutate: updateFolderName} = useMutation({
    onMutate() {
      setNewFolderLoading(true);
    },
    mutationFn({document, updatedFolderName}: {document: IDocument; updatedFolderName: string}) {
      return documentsApi.updateFolderName({
        document,
        updatedFolderName,
      });
    },
    onSuccess(result) {
      setNewFolderLoading(false);
      documentListState.updateDocument(result);
      dialogPopupState.hide();
    },
  });

  return (
    <React.Fragment>
      <SearchBar />

      <div className="flex flex-row justify-between items-baseline max-w-screen-lg w-full mx-auto mt-10 px-5">
        <div className="hidden sm:block">
          <TableBreadCrumbs folderPath={documentListState.folderPath} />
        </div>
        {isAdmin && (
          <button
            className="bg-red text-white rounded-lg px-2.5 py-2 font-medium flex flex-row items-center select-none"
            onClick={() => dialogPopupState.show()}
          >
            <MdCreateNewFolder className="mr-2" size={20} />
            New folder
          </button>
        )}
      </div>

      <div className="flex flex-row justify-between items-baseline max-w-screen-lg w-full mx-auto mt-10 px-5 sm:hidden">
        <TableBreadCrumbs folderPath={documentListState.folderPath} />
      </div>

      <div className="max-w-screen-lg w-full mx-auto mt-5 mb-10 px-5">
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          {isLoading ? (
            <TableSkeleton />
          ) : (
            <ul className="w-full divide-y divide-gray-200">
              {documentListState.filteredDocuments?.map((document) => (
                <TableRow document={document} isAdmin={isAdmin} key={getUUID()} enableActionButtons={true} />
              ))}
            </ul>
          )}
        </div>
        {documentListState.filteredDocuments.length === 0 && !isLoading && (
          <div className="flex justify-center mt-10">
            <img src={EmptyData} alt="Empty data" className="h-56" />
          </div>
        )}
      </div>

      {!dialogPopupState.isHidden && (
        <InteractiveDialog
          onSubmit={(value) =>
            createNewFolder({
              name: value,
              parentFolderId: documentListState.selectedFolder?.id,
            })
          }
          onUpdate={(value, docToUpdate) => updateFolderName({document: docToUpdate, updatedFolderName: value})}
          isLoading={isNewFolderLoading}
        />
      )}
      {!confirmationDialogState.isHidden && (
        <ConfirmationDialog onSubmit={() => deleteDocuments(confirmationDialogState.docToUpdate!)} isLoading={isDeleteLoading} />
      )}
    </React.Fragment>
  );
}

export default DocumentsTable;

import React from "react";
import {BsGoogle} from "react-icons/bs";
import CircularLoader from "./circular-loader";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {LoginFormData, LoginFormSchema} from "../utils/schemas";
import Alert from "./alert";
import {AlertType} from "../utils/enums/alert-type.enum";
import useLoginWithEmailAndPassword from "../hooks/useLoginWithEmailAndPassword";
import {AuthErrorCodes} from "firebase/auth";
import useGoogleLogin from "../hooks/useGoogleLogin";
import {useNavigate} from "react-router-dom";

function LoginPopup({toggleAuthState}: {toggleAuthState: (value: boolean) => void}) {
  const navigate = useNavigate();
  const {loading, error, loginWithEmailAndPassword} = useLoginWithEmailAndPassword();

  const {error: googleError, loading: googleLoginLoading, loginWithGoogle} = useGoogleLogin();

  const {register, handleSubmit} = useForm<LoginFormData>({
    resolver: yupResolver(LoginFormSchema),
  });

  const emailFieldError = () => error.isError && error.type === AuthErrorCodes.EMAIL_EXISTS;

  const alertError = () => error.isError && (error.type === AuthErrorCodes.INVALID_PASSWORD || error.type === AuthErrorCodes.INTERNAL_ERROR);

  return (
    <React.Fragment>
      <div className={`fixed rounded-lg bg-white top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full max-w-md`}>
        <div className="px-6 py-6 lg:px-8">
          <h3 className="mb-8 text-xl font-medium text-gray-900">Login to Disha Consultancy</h3>

          {alertError() && <Alert message={error.message || googleError.message} type={AlertType.WARNING} />}

          <button
            type="button"
            className="text-white bg-[#4285F4] hover:bg-[#4285F4]/90 focus:ring-4 focus:outline-none focus:ring-[#4285F4]/50 font-medium rounded-lg text-sm px-5 py-3.5 text-center flex items-center justify-center mr-2 mb-2 w-full"
            disabled={googleLoginLoading}
            onClick={async () => {
              await loginWithGoogle();
              navigate("/documents");
            }}
          >
            <span className="flex items-center">
              {googleLoginLoading && <CircularLoader />}
              <BsGoogle className="w-4 h-4 mr-2 -ml-1" />
              Login with Google
            </span>
          </button>

          <div className="inline-flex items-center justify-center w-full">
            <hr className="w-full h-1 my-8 bg-gray-200 border-0 rounded " />
            <div className="absolute px-4 -translate-x-1/2 bg-white left-1/2 ">
              <span className="w-5 h-5 text-gray-700">or</span>
            </div>
          </div>

          <form className="space-y-6" onSubmit={handleSubmit(loginWithEmailAndPassword)}>
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-900">Your email</label>
              <input
                {...register("email")}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                placeholder="name@company.com"
              />
              {emailFieldError() && <p>{error.message}</p>}
            </div>
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-900 ">Your password</label>
              <input
                {...register("password")}
                type="password"
                placeholder="••••••••"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 "
              />
            </div>
            <div className="flex justify-between">
              <span className="text-sm text-red hover:underline hover:cursor-pointer">Lost Password?</span>
            </div>
            <button
              type="submit"
              className="w-full text-white bg-red focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-3.5 text-center flex justify-center"
              disabled={loading}
            >
              <span className="flex flex-row">
                {loading && <CircularLoader />}
                Login to your account
              </span>
            </button>
            <div className="text-sm font-medium text-gray-600">
              Not registered?{" "}
              <span className="text-red hover:underline hover:cursor-pointer" onClick={() => toggleAuthState(false)}>
                Create account
              </span>
            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
}

export default LoginPopup;

import {create} from "zustand";
import {IDocument, IFolderPath} from "../utils/interfaces/documents";
import {IDocDeleteIds} from "../utils/interfaces/docDeleteIds";
import {createFolderPath} from "../utils/helpers/createFolderPath";
import * as _ from "lodash";
import {FileTypes} from "../utils/enums/file-types.enum";

const documentListStore = create<{
  filteredDocuments: IDocument[];
  documents: IDocument[];
  allDocuments: IDocument[];
  selectedFolder: IDocument | null;
  folderPath: IFolderPath[];
  setDocuments: (documents: IDocument[], docId?: string) => void;
  add: (newDocument: IDocument) => void;
  updateDocument: (document: IDocument) => void;
  delete: (ids: IDocDeleteIds) => void;
  filter: (query: string) => void;
  navigateToFolder: (parentFolderId?: string) => void;
}>();

export const useDocumentListState = documentListStore((set) => ({
  filteredDocuments: [],
  documents: [],
  allDocuments: [],
  selectedFolder: null,
  folderPath: [],
  add(newDocument) {
    set((prev) => {
      const allDocs: IDocument[] = JSON.parse(JSON.stringify([...prev.allDocuments, newDocument]));

      if (newDocument.type === FileTypes.folder) {
        const doc = allDocs.find((doc) => doc.id === newDocument.parentFolderId);
        if (doc) {
          doc.subFolderIds = [...doc.subFolderIds, newDocument.id];
        }
      }

      return {
        documents: [...prev.documents, newDocument],
        filteredDocuments: [...prev.filteredDocuments, newDocument],
        allDocuments: allDocs,
      };
    });
  },
  updateDocument(document) {
    set((prev) => {
      const allDocs: IDocument[] = JSON.parse(JSON.stringify(prev.allDocuments));
      const docs: IDocument[] = JSON.parse(JSON.stringify(prev.documents));

      let updateAllDoc: IDocument | undefined = allDocs.find((doc) => doc.id === document.id);
      if (updateAllDoc) {
        if (updateAllDoc.type === FileTypes.folder) updateAllDoc.name = document.name;
        else updateAllDoc.originalName = document.originalName;
      }

      let updateDoc: IDocument | undefined = docs.find((doc) => doc.id === document.id);
      if (updateDoc) {
        if (updateDoc.type === FileTypes.folder) updateDoc.name = document.name;
        else updateDoc.originalName = document.originalName;
      }

      return {
        allDocuments: allDocs,
        documents: docs,
        filteredDocuments: docs,
      };
    });
  },
  setDocuments(docs, docId) {
    set(() => {
      const documents = _.orderBy(
        docId ? docs.filter((doc) => doc.parentFolderId === docId) : docs.filter((doc) => !doc.parentFolderId),
        ["uploadedAt"],
        ["desc"]
      );
      const selectedFolder = docs.find((doc) => doc.id === docId);

      const folderPath = createFolderPath({
        allDocuments: docs,
        folderId: selectedFolder?.id,
      }).reverse();

      return {
        documents,
        filteredDocuments: documents,
        allDocuments: docs,
        selectedFolder,
        folderPath,
      };
    });
  },
  navigateToFolder(parentFolderId) {
    set((prev) => {
      const documents = _.orderBy(
        parentFolderId
          ? prev.allDocuments.filter((doc) => doc.parentFolderId === parentFolderId)
          : prev.allDocuments.filter((doc) => !doc.parentFolderId),
        ["uploadedAt"],
        ["asc"]
      );

      const selectedFolder = prev.allDocuments.find((doc) => doc.id === parentFolderId);

      const folderPath = createFolderPath({
        allDocuments: prev.allDocuments,
        folderId: selectedFolder?.id,
      }).reverse();

      return {
        documents,
        filteredDocuments: documents,
        selectedFolder,
        folderPath,
      };
    });
  },
  delete({fileIds, subFolderIds}) {
    set((prev) => {
      const allIds = [...fileIds.map((file) => file.fid), ...subFolderIds.map((id) => id.fid)];
      const allDocs = JSON.parse(JSON.stringify(prev.allDocuments.filter((doc) => !allIds.includes(doc.fid))));
      const docs = JSON.parse(JSON.stringify(prev.documents.filter((doc) => !allIds.includes(doc.fid))));

      return {
        allDocuments: allDocs,
        documents: docs,
        filteredDocuments: docs,
      };
    });
  },
  filter(query) {
    set((prev) => {
      if (query) {
        const searchQuery = query.toLowerCase().split(" ");
        return {
          filteredDocuments: prev.documents.filter((doc) => {
            if (doc.type === FileTypes.folder) {
              return searchQuery.map((value) => doc.name.toLowerCase().includes(value)).some(Boolean);
            }
            return searchQuery.map((value) => doc.originalName.toLowerCase().includes(value)).some(Boolean);
          }),
        };
      }
      return {
        filteredDocuments: prev.documents,
      };
    });
  },
}));

import { FileTypes } from "../enums/file-types.enum";
import { IDocDeleteIds } from "../interfaces/docDeleteIds";
import { IDocument } from "../interfaces/documents";

function getSubFolderAndFileIds(allDocuments: IDocument[], document?: IDocument): IDocDeleteIds {
  let subFolderIds: {id: string; fid: string}[] = [];
  let fileIds: IDocument[] = [];

  if (!document) return {subFolderIds, fileIds}

  fileIds = [...fileIds, ...allDocuments.filter(doc => (doc.parentFolderId === document.id || doc.id === document.id) && doc.type !== FileTypes.folder)]

  if (document.subFolderIds) {
    // loop through all the child folders of the given folder
    for (let childFolderId of document.subFolderIds) {
      // recursively call this function on each child folder to get their subfolder and file IDs
      const { subFolderIds: childSubFolders, fileIds: childFiles } = getSubFolderAndFileIds(allDocuments, allDocuments.find(doc => doc.id === childFolderId));
      
      // add the child subfolder and file IDs to the parent arrays
      subFolderIds = [...subFolderIds, ...childSubFolders];
      fileIds = [...fileIds, ...childFiles];
    }
  }

  // add the subfolder IDs and file IDs of the current folder to the arrays
  if (document.subFolderIds) {
    subFolderIds = [...subFolderIds, ...document.subFolderIds.map((id: string) => ({id, fid: ''}))];
  }

  return { subFolderIds, fileIds };
}

export default getSubFolderAndFileIds
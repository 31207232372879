import {MdClose} from "react-icons/md";

function Chip({label, onRemove}: {label: string; onRemove: () => void}) {
  return (
    <span className="inline-flex items-center px-2 py-1 mr-2 mb-2 text-sm font-medium text-gray-800 bg-gray-100 rounded">
      {label}
      <button
        type="button"
        className="inline-flex items-center p-0.5 ml-2 text-sm text-gray-400 bg-transparent rounded-sm hover:bg-gray-200 hover:text-gray-900"
        aria-label="Remove"
        onClick={onRemove}
      >
        <MdClose className="w-3.5 h-3.5" />
        <span className="sr-only">Remove badge</span>
      </button>
    </span>
  );
}

export default Chip;

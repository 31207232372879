import {FileTypes} from "../enums/file-types.enum";
import {IDocDeleteIds} from "../interfaces/docDeleteIds";
import {IDocument} from "../interfaces/documents";
import getSubFolderAndFileIds from "./getSubFolderAndFileIds";

export const getDeleteDocIds = ({document, allDocuments}: {document: IDocument; allDocuments: IDocument[]}): IDocDeleteIds => {
  if (document.type !== FileTypes.folder) {
    return {
      fileIds: [document],
      subFolderIds: [],
    };
  }

  const deleteDocList: IDocDeleteIds = getSubFolderAndFileIds(allDocuments, document);

  deleteDocList.subFolderIds = allDocuments
    .filter((allDoc) => deleteDocList.subFolderIds.map((id) => id.id).includes(allDoc.id))
    .map((allDoc) => ({
      id: allDoc.id,
      fid: allDoc.fid,
    }));

  deleteDocList.subFolderIds.push({
    fid: document.fid,
    id: document.id,
  });

  return deleteDocList;
};

import {AuthErrorCodes, createUserWithEmailAndPassword} from "firebase/auth";
import {addDoc, collection, getDocs, query, where} from "firebase/firestore";
import {useCallback, useState} from "react";
import {auth, db} from "../firebase";
import {IErrorState} from "../utils/interfaces/error";
import {LoginFormData} from "../utils/schemas";
import { useSignupPopupState } from "../store/signupPopupState";

function useCreateUSerWithEmailAndPassword() {
  const signupPopupState = useSignupPopupState();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<IErrorState>({
    message: "",
    isError: false,
  });

  const createUser = useCallback(async ({email, password}: LoginFormData) => {
    setLoading(true);
    try {
      const docs = await getDocs(query(collection(db, "users"), where("email", "==", email)));
      if (!docs.docs.length) {
        const res = await createUserWithEmailAndPassword(auth, email, password);
        const user = res.user;
        await addDoc(collection(db, "users"), {
          uid: user.uid,
          name: user.displayName,
          authProvider: user.providerId,
          email: user.email,
          isAdmin: false
        });
        setLoading(false);
        signupPopupState.hide();
      }

      setError({
        isError: true,
        message: "Account already created with this email.",
        type: AuthErrorCodes.EMAIL_EXISTS,
      });
      setLoading(false);
    } catch (error) {
      setError({
        isError: true,
        message: "Account already created with this email.",
        type: AuthErrorCodes.EMAIL_EXISTS,
      });
      setLoading(false);
    }
  },[signupPopupState]);

  return {loading, error, createUser};
}

export default useCreateUSerWithEmailAndPassword
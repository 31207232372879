import { AuthErrorCodes, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import {addDoc, collection, getDocs, query, where} from "firebase/firestore";
import { useCallback, useState } from "react";
import { auth, db } from "../firebase";
import { IErrorState } from "../utils/interfaces/error";
import { useLoginPopupState } from "../store/loginPopupState";

function useGoogleLogin() {
  const loginPopupState = useLoginPopupState();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<IErrorState>({
    message: "",
    isError: false,
  });

  const loginWithGoogle = useCallback(async () => {
    setLoading(true)
    try {
      const res = await signInWithPopup(auth, new GoogleAuthProvider());
      const user = res.user;
  
      const docs = await getDocs(query(collection(db, "users"), where("uid", "==", user.uid)));
      if (docs.docs.length === 0) {
        await addDoc(collection(db, "users"), {
          uid: user.uid,
          name: user.displayName,
          authProvider: user.providerId,
          email: user.email,
          isAdmin: false
        });
      }

      setLoading(false)
      loginPopupState.hide()
    } catch (error) {
      setError({
        isError: true,
        message: "Something went wrong, please try again later.",
        type: AuthErrorCodes.INTERNAL_ERROR,
      });
      setLoading(false);
    }
  }, [loginPopupState])

  return {loading, error, loginWithGoogle}

}

export default useGoogleLogin
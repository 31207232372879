import React, {useContext} from "react";
import {Disclosure} from "@headlessui/react";
import {Bars3Icon, XMarkIcon} from "@heroicons/react/24/outline";
import {Link} from "react-router-dom";
import Logo from "../logo.svg";
import {IPage} from "../utils/interfaces/pages";
import {UserContext} from "../contexts";
import {signOut} from "firebase/auth";
import {auth} from "../firebase";
import {MdPhone} from "react-icons/md";
import {FaCaretDown} from "react-icons/fa";

function Header({pages}: {pages: IPage[]}) {
  const user = useContext(UserContext);

  return (
    <React.Fragment>
      <Disclosure as="nav" className="bg-white/80 sticky top-0 w-full z-48 backdrop-blur-md z-10">
        {({open}) => (
          <>
            <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
              <div className="relative flex h-16 items-center justify-between">
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                  {/* Mobile menu button*/}
                  <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? <XMarkIcon className="block h-6 w-6" aria-hidden="true" /> : <Bars3Icon className="block h-6 w-6" aria-hidden="true" />}
                  </Disclosure.Button>
                </div>
                <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                  <div className="flex flex-shrink-0 items-center">
                    <img className="block h-8 w-auto lg:hidden" src={Logo} alt="Disha Consultancy" />
                    <img className="hidden h-8 w-auto lg:block" src={Logo} alt="Disha Consultancy" />
                  </div>
                  <div className="hidden sm:ml-6 sm:block">
                    <div className="flex space-x-4">
                      {pages.map((page) => (
                        <Link to={page.href} key={page.label} className="text-black rounded-md px-3 py-2 text-sm font-medium">
                          {page.label}
                        </Link>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                  {!user?.user && (
                    <div>
                      <LoginDropdown />
                    </div>
                  )}
                  {user?.user && (
                    <button className="text-black rounded-lg px-2.5 py-2 font-medium mr-4 hidden sm:block" onClick={() => signOut(auth)}>
                      Logout
                    </button>
                  )}
                  <a href="tel:6356542902" className="bg-red text-white rounded-lg px-2.5 py-2 font-medium flex flex-row items-center select-none">
                    <MdPhone className="mr-0 sm:mr-2" size={20} />
                    <span className="hidden sm:block">Call Now</span>
                  </a>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <div className="space-y-1 px-2 pt-2 pb-3">
                {pages.map((page) => (
                  <Link to={page.href} key={page.label} className="text-black block rounded-md px-3 py-2 text-base font-medium">
                    {page.label}
                  </Link>
                ))}
                {!user?.user ? (
                  <>
                    <div>
                      <a
                        href="http://www.disha-consultancy.co.in/"
                        rel="noreferrer"
                        className="text-black block rounded-md px-3 py-2 text-base font-medium"
                      >
                        Employer Login
                      </a>
                    </div>
                    <div>
                      <a href="https://vishva.mypaybook.in/" rel="noreferrer" className="text-black block rounded-md px-3 py-2 text-base font-medium">
                        Employee Login
                      </a>
                    </div>
                  </>
                ) : (
                  <Link to="#" className="text-black block rounded-md px-3 py-2 text-base font-medium" onClick={() => signOut(auth)}>
                    Logout
                  </Link>
                )}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </React.Fragment>
  );
}

const LoginDropdown = () => {
  return (
    <div className="relative group hidden sm:block">
      <button className="bg-red text-white rounded-lg px-2.5 py-2 font-medium mr-4 flex flex-row items-center focus:outline-none" type="button">
        <span className="pr-2">Login</span> <FaCaretDown />
      </button>

      <div className="z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44  absolute invisible group-focus-within:visible opacity-0 group-focus-within:opacity-100 transition-all duration-300">
        <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultButton">
          <li>
            <a href="http://www.disha-consultancy.co.in/" rel="noreferrer" className="text-black block rounded-md px-3 py-2 text-base font-medium">
              Employer Login
            </a>
          </li>
          <li>
            <a href="https://vishva.mypaybook.in/" rel="noreferrer" className="text-black block rounded-md px-3 py-2 text-base font-medium">
              Employee Login
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Header;

import {MdVisibility} from "react-icons/md";
import getUUID from "../../utils/helpers/getUuid";
import {IDocument} from "../../utils/interfaces/documents";
import {FileTypes} from "../../utils/enums/file-types.enum";

function OpenFileButton({document}: {document: IDocument}) {
  if (
    document.type !== FileTypes.folder &&
    (document.type === FileTypes.pdf || document.type === FileTypes.jpeg || document.type === FileTypes.png || document.type === FileTypes.jpg)
  ) {
    return (
      <button
        type="button"
        className="text-blue-700 border border-blue-700 hover:bg-blue-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center items-center inline-flex"
        onClick={() => window.open(document.fileUrl, "_blank")}
        key={getUUID()}
      >
        <MdVisibility className="w-5 h-5" />
      </button>
    );
  }

  return null;
}

export default OpenFileButton;

import {WhereFilterOp, addDoc, collection, deleteDoc, doc, getDocs, query, updateDoc, where} from "firebase/firestore";
import Web3StorageApi from "./Web3StorageApi";
import {db} from "../firebase";
import * as _ from "lodash";
import {CollectionNames} from "../utils/constants/collectionNames.constant";
import {IFiles} from "../utils/interfaces/files";
import {IDocument} from "../utils/interfaces/documents";
import {getDeleteDocIds} from "../utils/helpers/getDeleteDocIds";
import {FileTypes} from "../utils/enums/file-types.enum";
import getUUID from "../utils/helpers/getUuid";

class DocumentsApi extends Web3StorageApi {
  async insertFiles({files, parentFolderId}: {files: IFiles[]; parentFolderId?: string}) {
    const uploadedFiles = await this.uploadFiles({files, parentFolderId});

    const insertedFiles = await Promise.all(
      uploadedFiles.map(async (uploadedFile) => {
        const result = await this.#addDoc(uploadedFile);

        const insertedFile: IDocument = {
          ...uploadedFile,
          fid: result.id,
        };

        return insertedFile;
      })
    );

    return insertedFiles;
  }

  async deleteFiles({document, allDocuments}: {document: IDocument; allDocuments: IDocument[]}) {
    const {fileIds, subFolderIds} = getDeleteDocIds({document, allDocuments});

    // delete from the collection
    await Promise.all([...fileIds.map((file) => file.fid), ...subFolderIds.map((folder) => folder.fid)].map((fid) => this.#deleteDoc(fid)));

    // delete files from pinata
    await Promise.all(fileIds.map((fileId) => this.deleteFile(fileId.fileUrl.split("/").reverse()[0])));

    if (document.parentFolderId) {
      // Update the parent folder subFolderIds
      const selectedDoc = await this.#getDocsWhere("id", "==", document.parentFolderId);

      selectedDoc.forEach(async (document) => {
        const parentDoc: IDocument = document.data();
        const value = {
          subFolderIds: _.difference(
            parentDoc.subFolderIds,
            subFolderIds.map((id) => id.id)
          ),
        };
        await this.#updateDoc({fid: document.id, value});
      });
    }

    return {fileIds, subFolderIds};
  }

  async fetchDocuments() {
    const docs = await this.#getDocs();

    let documents: IDocument[] = [];
    docs.forEach((doc) =>
      documents.push({
        ...doc.data(),
        fid: doc.id,
      })
    );

    return documents;
  }

  async updateFolderName({document, updatedFolderName}: {document: IDocument; updatedFolderName: string}) {
    const payload = document.type === FileTypes.folder ? {name: updatedFolderName} : {originalName: updatedFolderName};

    await this.#updateDoc({
      fid: document.fid,
      value: payload,
    });

    return {
      ...document,
      ...payload,
    };
  }

  async createFolder({name, parentFolderId}: {name: string; parentFolderId?: string}) {
    const payload: IDocument = {
      name,
      type: FileTypes.folder,
      uploadedAt: new Date(),
      id: getUUID(),
      parentFolderId: parentFolderId ?? null,
      subFolderIds: [],
    };

    await this.#addDoc(payload);

    if (parentFolderId) {
      const parentFolderDocument = await this.#getDocsWhere("id", "==", parentFolderId);

      if (!parentFolderDocument.empty) {
        parentFolderDocument.forEach(async (data) => {
          const document: IDocument = data.data();
          const subFolderIds = [...document.subFolderIds, payload.id];
          await this.#updateDoc({fid: data.id, value: {subFolderIds}});
        });
      }
    }

    let result: IDocument = {};
    const docs = await this.#getDocsWhere("id", "==", payload.id);
    docs.forEach(
      (doc) =>
        (result = {
          ...doc.data(),
          fid: doc.id,
        })
    );

    return result;
  }

  #deleteDoc(fid: string) {
    return deleteDoc(doc(db, CollectionNames.documents, fid));
  }

  #getDocsWhere(fieldPath: string, opStr: WhereFilterOp, value: string) {
    return getDocs(query(collection(db, CollectionNames.documents), where(fieldPath, opStr, value)));
  }

  #getDocs() {
    return getDocs(query(collection(db, CollectionNames.documents)));
  }

  #updateDoc({fid, value}: {fid: string; value: any}) {
    return updateDoc(doc(db, CollectionNames.documents, fid), value);
  }

  #addDoc(document: IDocument) {
    return addDoc(collection(db, CollectionNames.documents), document);
  }
}

export default DocumentsApi;

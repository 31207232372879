import {useNavigate} from "react-router-dom";
import {IDocument} from "../utils/interfaces/documents";
import Highlighter from "react-highlight-words";
import getUUID from "../utils/helpers/getUuid";
import {FileTypes} from "../utils/enums/file-types.enum";
import {renderFileIcon} from "../utils/helpers/renderFileIcons";
import OpenFileButton from "./tableRowActionButtons/open-file-button";
import DownloadFileButton from "./tableRowActionButtons/download-file-button";
import DeleteFileButton from "./tableRowActionButtons/delete-file-button";
import EditFileButton from "./tableRowActionButtons/edit-file-button";

function TableRow({
  document,
  isAdmin = false,
  enableActionButtons = true,
  queryKeywords = [],
  searchTableRow = false,
  onSearchTableRowDoubleClick,
}: {
  document: IDocument;
  isAdmin?: boolean;
  enableActionButtons?: boolean;
  queryKeywords?: string[];
  searchTableRow?: boolean;
  onSearchTableRowDoubleClick?: VoidFunction;
}) {
  const navigate = useNavigate();

  function onDoubleClick(document: IDocument, event: React.MouseEvent<HTMLLIElement, MouseEvent>) {
    event.preventDefault();
    if (document.type === FileTypes.folder) {
      if (searchTableRow && onSearchTableRowDoubleClick) onSearchTableRowDoubleClick();
      navigate(`/documents/${document.id}`);
    }

    if (searchTableRow) {
      if (document.type !== FileTypes.folder) {
        if (onSearchTableRowDoubleClick) onSearchTableRowDoubleClick();
        navigate(`/documents/${document.parentFolderId}`);
      }
    }
  }

  let listClass = document.type === FileTypes.folder ? "hover:bg-gray-100 hover:cursor-pointer" : "";

  if (searchTableRow) listClass = "hover:bg-gray-100 hover:cursor-pointer";

  return (
    <li className={`p-3 sm:p-4 group ${listClass}`} onDoubleClick={(event) => onDoubleClick(document, event)} key={getUUID()}>
      <div className="flex items-center space-x-4">
        <div className="flex-shrink-0">{renderFileIcon(document.type)}</div>
        <div className="flex-1 min-w-0">
          <p className="text-sm font-medium text-gray-900 truncate ">
            <Highlighter
              className="select-none"
              highlightClassName="highlighted"
              searchWords={queryKeywords}
              autoEscape={true}
              textToHighlight={document.type !== FileTypes.folder ? document.originalName : document.name}
            />
          </p>
        </div>

        {enableActionButtons && (
          <div className="inline-flex items-center text-base font-semibold text-gray-900 md:invisible group-hover:visible">
            <OpenFileButton document={document} />

            <DownloadFileButton document={document} />

            <DeleteFileButton isAdmin={isAdmin} document={document} />

            <EditFileButton isAdmin={isAdmin} document={document} />
          </div>
        )}
      </div>
    </li>
  );
}

export default TableRow;

import React from "react";
import CircularLoader from "./circular-loader";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {LoginFormSchema, SignupFormData} from "../utils/schemas";
import Alert from "./alert";
import {AlertType} from "../utils/enums/alert-type.enum";
import useCreateUSerWithEmailAndPassword from "../hooks/useCreateUserWithEmailAndPassword";

function SignupPopup({toggleAuthState}: {toggleAuthState: (value: boolean) => void}) {
  const {loading, error, createUser} = useCreateUSerWithEmailAndPassword();

  const {
    register,
    handleSubmit,
    setError,
    formState: {errors},
  } = useForm<SignupFormData>({
    resolver: yupResolver(LoginFormSchema),
  });

  function submit(payload: SignupFormData) {
    if (payload.password !== payload.confirmPassword) {
      setError("confirmPassword", {
        type: "custom",
        message: "Password doesnot match.",
      });
      return;
    }

    createUser(payload);
  }

  return (
    <React.Fragment>
      <div className={`fixed rounded-lg bg-white top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-[51] w-full max-w-md`}>
        <div className="px-6 py-6 lg:px-8">
          <h3 className="mb-8 text-xl font-medium text-gray-900">Signup to Disha Consultancy</h3>

          {error.isError && <Alert message={error.message} type={AlertType.WARNING} />}

          <form className="space-y-6" onSubmit={handleSubmit(submit)}>
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-900">Your email</label>
              <input
                {...register("email")}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="name@company.com"
              />
              <p>{errors.email?.message}</p>
            </div>

            <div>
              <label className="block mb-2 text-sm font-medium text-gray-900 ">Your password</label>
              <input
                {...register("password")}
                type="password"
                placeholder="••••••••"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 "
              />
              <p>{errors.password?.message}</p>
            </div>

            <div>
              <label className="block mb-2 text-sm font-medium text-gray-900 ">Confirm your password</label>
              <input
                {...register("confirmPassword")}
                type="password"
                placeholder="••••••••"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 "
              />
              <p>{errors.confirmPassword?.message}</p>
            </div>

            <button
              type="submit"
              className="w-full text-white bg-red focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-3.5 text-center flex justify-center"
              disabled={loading}
            >
              <span className="flex flex-row">
                {loading && <CircularLoader />}
                Signup to your account
              </span>
            </button>

            <div className="text-sm font-medium text-gray-600 ">
              <span className="text-red hover:underline hover:cursor-pointer" onClick={() => toggleAuthState(true)}>
                Already have an account?
              </span>
            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
}

export default SignupPopup;

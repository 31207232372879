import {useRef, useState} from "react";
import {MdSearch} from "react-icons/md";
import {useDocumentListState} from "../store/documentListState";
import getUUID from "../utils/helpers/getUuid";
import TableRow from "./table-row";
import {IDocument} from "../utils/interfaces/documents";
import {debounce} from "lodash";

function SearchBar() {
  const searchInputRef = useRef<HTMLInputElement>(null);
  const documentListState = useDocumentListState();

  const [searchQuery, setSearchQuery] = useState("");
  const [queriedDocuments, setQueriedDocuments] = useState<IDocument[]>([]);

  const debounceFilteredDocuments = debounce((query: string) => {
    setQueriedDocuments(
      documentListState.allDocuments.filter((doc: IDocument) => {
        return doc.name.toLowerCase().includes(query.toLowerCase());
      })
    );
    setSearchQuery(query);
  }, 500);

  const filterDocuments = (query: string) => {
    if (!query) {
      setQueriedDocuments([]);
      setSearchQuery("");
      return;
    }
    debounceFilteredDocuments(query);
  };

  const onSearchTableRowDoubleClick = () => {
    setQueriedDocuments([]);
    setSearchQuery("");
    if (searchInputRef.current) searchInputRef.current.value = "";
  };

  return (
    <div className="max-w-screen-lg w-full m-auto pt-10 relative">
      <form className="mx-5">
        <label htmlFor="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only ">
          Search
        </label>
        <div className="relative">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <MdSearch className="w-5 h-5 text-gray-500" />
          </div>
          <input
            type="search"
            ref={searchInputRef}
            id="default-search"
            className="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
            placeholder="Search documents ..."
            onChange={(event) => filterDocuments(event.target.value ?? "")}
          />
        </div>
      </form>

      <div className="bg-white rounded-lg absolute mt-3 left-0 shadow-lg z-10 mx-5 w-full max-h-[400px] overflow-y-auto">
        <ul className="w-full divide-y divide-gray-200">
          {queriedDocuments.map((document) => (
            <TableRow
              document={document}
              key={getUUID()}
              queryKeywords={searchQuery.split(" ")}
              enableActionButtons={false}
              searchTableRow={true}
              onSearchTableRowDoubleClick={onSearchTableRowDoubleClick}
            />
          ))}
        </ul>
      </div>
    </div>
  );
}

export default SearchBar;

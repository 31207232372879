import {create} from "zustand";
import {PopupState} from "../utils/interfaces/popup-state";

const dialogPopupState = create<PopupState>();

export const useDialogPopupState = dialogPopupState((set) => ({
  isHidden: true,
  isUpdateMode: false,
  docToUpdate: null,
  hide() {
    set(() => ({
      isHidden: true,
      isUpdateMode: false,
      docToUpdate: null,
    }));
  },
  show(showOptions) {
    set(() => ({
      isHidden: false,
      isUpdateMode: showOptions?.isUpdateMode,
      docToUpdate: showOptions?.docToUpdate,
    }));
  },
}));

import React from "react";
import {MdClose} from "react-icons/md";
import {useConfirmationDialogState} from "../store/confirmationDialogState";
import CircularLoader from "./circular-loader";

function ConfirmationDialog({onSubmit, isLoading}: {onSubmit: () => void; isLoading: boolean}) {
  const confirmationDialogState = useConfirmationDialogState();

  return (
    <React.Fragment>
      <div className="bg-black/20 fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] md:h-full"></div>
      <div className="fixed w-full h-full max-w-md md:h-auto top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50">
        <div className="relative bg-white rounded-lg shadow">
          <div className="flex items-center justify-between p-5 rounded-t">
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
              data-modal-hide="small-modal"
              disabled={isLoading}
              onClick={() => confirmationDialogState.hide()}
            >
              <MdClose className="w-5 h-5" />
              <span className="sr-only">Close modal</span>
            </button>
          </div>

          <div className="p-6">{confirmationDialogState.message}</div>

          <div className="flex justify-end items-center p-6 space-x-2 rounded-b">
            <button
              data-modal-hide="small-modal"
              type="button"
              className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10"
              onClick={() => confirmationDialogState.hide()}
              disabled={isLoading}
            >
              Cancel
            </button>
            <button
              data-modal-hide="small-modal"
              type="button"
              className="text-white bg-red hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 flex flex-row justify-evenly"
              onClick={onSubmit}
              disabled={isLoading}
            >
              {isLoading && <CircularLoader />}
              Delete
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ConfirmationDialog;

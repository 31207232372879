import React, {useState} from "react";
import LoginPopup from "../components/login-popup";
import SignupPopup from "../components/signup-popup";

function AuthPage() {
  const [isLoginState, setLoginState] = useState(true);

  const toggleAuthState = (value: boolean) => setLoginState(value);
  return (
    <React.Fragment>
      {isLoginState ? <LoginPopup toggleAuthState={toggleAuthState} /> : <SignupPopup toggleAuthState={toggleAuthState} />}
    </React.Fragment>
  );
}

export default AuthPage;

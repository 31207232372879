import React from "react";
import services from "../utils/services";

function HomePage() {
  return (
    <React.Fragment>
      <div>
        <img src={require("../assets/images/image1.jpeg")} alt="home page" />
      </div>

      <div className="text-center pt-10 my-5 text-red font-bold font-roboto text-2xl">Services</div>

      <div className="max-w-screen-xl m-auto grid xl:grid-cols-4 lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-16 p-9">
        {services.map((service) => (
          <div className="flex flex-col" key={service.name}>
            <img src={service.svg} alt={service.name} className="h-56" />
            <div className="text-center font-roboto font-bold text-black py-5 text-lg">{service.name}</div>
          </div>
        ))}
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2">
        <div className="p-10 lg:p-20 bg-roseRed/10">
          <h1 className="text-red font-bold text-4xl font-roboto mb-10">About</h1>
          <p className="text-base lg:text-lg font-roboto">
            Our labour law consulting website provides expert guidance and support to employers and employees in navigating the complex landscape of
            labour law. We understand that employment relationships can be challenging, and the legal requirements surrounding them can be difficult
            to navigate. Our experienced consultants offer a wide range of services, including advice on employment contracts, compliance with
            employment standards legislation, workplace policies and procedures, and human rights issues. Whether you are an employer looking to
            implement a new policy or an employee seeking guidance on your rights, we are here to help. We are committed to providing timely and
            practical advice that is tailored to your specific needs. We pride ourselves on building strong relationships with our clients, and we are
            committed to providing a high level of service at all times.
          </p>
          <br />
          <p className="text-base lg:text-lg font-roboto">
            Our team of experts is dedicated to staying up to date with the latest developments in labour law. This ensures that our clients receive
            the most accurate and relevant advice possible. We value professionalism, integrity, and transparency and take the time to understand your
            business or personal circumstances to provide the best possible advice. If you are looking for a trusted and reliable labour law
            consultant, look no further. Contact us today to learn more about our services and how we can help you navigate the complexities of
            employment law.
          </p>
        </div>
        <div
          style={{
            backgroundImage: `url(${require("../assets/images/pattern.png")})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          className="hidden lg:block"
        ></div>
      </div>

      <div>
        <img src={require("../assets/images/image2.jpg")} alt="home page" />
      </div>

      <footer className="text-center">
        <div className="py-20">
          <h5 className="text-red font-bold font-roboto">Contacts</h5>
          <p className="text-black font-mono font-bold text-lg">Lets work together</p>
          <br />
          <p className="text-black font-mono text-sm">
            F.F. 10/B, RADHE SHOPPING MALL <br />
            NEAR OSIA MALL, <br />
            KHOKHARA, AHMEDABAD 380008
          </p>
          <br />
          <div className="text-black font-mono text-sm">
            <a href="mailto:contact@disha-consultancy.in">contact@disha-consultancy.in</a>
          </div>
          <div className="text-black font-mono text-sm">
            <a href="tel:6356542902">6356542902</a>
          </div>
        </div>

        <div className="py-5 font-roboto text-black border-t-2 border-slate-200">@{new Date().getFullYear()} by Disha Consultancy</div>
      </footer>
    </React.Fragment>
  );
}

export default HomePage;

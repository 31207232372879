import {FileTypes} from "../enums/file-types.enum";
import {AiFillFileWord} from "react-icons/ai";
import {SiGooglesheets} from "react-icons/si";
import {HiDocument} from "react-icons/hi";
import {MdFolder, MdImage} from "react-icons/md";
import {TbCsv, TbPdf} from "react-icons/tb";

export const renderFileIcon = (type: string) => {
  let size = 30;
  switch (type) {
    case FileTypes.folder:
      return <MdFolder size={size} color="#4f3f8f" />;
    case FileTypes.pdf:
      return <TbPdf size={size} color="#4f3f8f" />;
    case FileTypes.csv:
      return <TbCsv size={size} color="#7c8908" />;
    case FileTypes.png:
    case FileTypes.jpg:
    case FileTypes.jpeg:
      return <MdImage size={size} color="#27a165" />;
    case FileTypes.word:
      return <AiFillFileWord size={size} color="#a754fd" />;
    case FileTypes.excel:
      return <SiGooglesheets size={size} color="#b76533" />;

    default:
      return <HiDocument size={size} color="#336c9e" />;
  }
};

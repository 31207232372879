import { IDocument, IFolderPath } from "../interfaces/documents";

export const createFolderPath = ({allDocuments, folderId, path= []}:{
  allDocuments: IDocument[], 
  folderId?: string,
  path?: IFolderPath[]
}): IFolderPath[] => {
  const document = allDocuments.find(doc => doc.id === folderId)

  if (!document) return [];

  const folderPath: IFolderPath = {
    name: document.name,
    folderId: document.id
  }

  path.push(folderPath)
  if (!document.parentFolderId) {
    return path;
  }

  const parentFolderPath = createFolderPath({allDocuments, folderId: document.parentFolderId, path})
  return parentFolderPath;
}
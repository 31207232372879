import {AuthErrorCodes, signInWithEmailAndPassword} from "firebase/auth";
import {collection, getDocs, query, where} from "firebase/firestore";
import {useCallback, useState} from "react";
import {auth, db} from "../firebase";
import {IErrorState} from "../utils/interfaces/error";
import {LoginFormData} from "../utils/schemas";
import {useLoginPopupState} from "../store/loginPopupState";

function useLoginWithEmailAndPassword() {
  const loginPopupState = useLoginPopupState();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<IErrorState>({
    message: "",
    isError: false,
  });

  const loginWithEmailAndPassword = useCallback(
    async ({email, password}: LoginFormData) => {
      setLoading(true);
      try {
        const docs = await getDocs(query(collection(db, "users"), where("email", "==", email)));
        if (docs.docs.length) {
          await signInWithEmailAndPassword(auth, email, password);
          setLoading(false);
          loginPopupState.hide();
        }

        setError({
          isError: true,
          message: "This email doesnot match with any account.",
          type: AuthErrorCodes.EMAIL_EXISTS,
        });
        setLoading(false);
      } catch (error) {
        setError({
          isError: true,
          message: "Incorrect email or password",
          type: AuthErrorCodes.INVALID_PASSWORD,
        });
        setLoading(false);
      }
    },
    [loginPopupState]
  );

  return {loading, error, loginWithEmailAndPassword};
}
export default useLoginWithEmailAndPassword;

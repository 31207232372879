import {TiWarning} from "react-icons/ti";
import {AlertType} from "../utils/enums/alert-type.enum";

function Alert({message, type}: {message: string; type?: AlertType}) {
  let classes = "text-blue-800 border-blue-300 bg-blue-50";

  switch (type) {
    case AlertType.WARNING:
      classes = "text-yellow-800 border-yellow-300 bg-yellow-50";
      break;

    default:
      break;
  }

  return (
    <div className={`flex p-4 mb-4 text-sm border rounded-lg ${classes}`} role="alert">
      <TiWarning className="flex-shrink-0 inline w-5 h-5 mr-3" />
      <span className="sr-only">Info</span>
      <div>{message}</div>
    </div>
  );
}

export default Alert;

import {IDocument} from "../utils/interfaces/documents";
import {IFiles} from "../utils/interfaces/files";
import getUUID from "../utils/helpers/getUuid";
import axios from "axios";

abstract class Web3StorageApi {
  protected async uploadFiles({files, parentFolderId}: {files: IFiles[]; parentFolderId?: string}) {
    const uploadedFiles: IDocument[] = await Promise.all(
      files.map(async (file) => {
        const dateTime = new Date();
        const newFileName = `${dateTime.getTime()}_${file.file?.name.split(" ").join("_")}`;
        let cid;

        try {
          const form = new FormData();
          form.append("file", new Blob([file.file as File]));
          const ipfsRes = await axios.post("https://api.pinata.cloud/pinning/pinFileToIPFS", form, {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_PINATA_JWT}`,
              "Content-Type": "multipart/form-data",
            },
          });

          cid = ipfsRes.data.IpfsHash;
        } catch (error) {
          throw error;
        }

        return {
          originalName: file.file?.name,
          name: newFileName,
          fileUrl: `https://coral-shocked-firefly-354.mypinata.cloud/ipfs/${cid}`,
          type: file.file?.type!,
          uploadedAt: dateTime,
          id: getUUID(),
          parentFolderId: parentFolderId ?? null,
        };
      })
    );

    return uploadedFiles;
  }

  protected deleteFile(cid: string) {
    return axios.delete(`https://api.pinata.cloud/pinning/unpin/${cid}`, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_PINATA_JWT}`,
      },
    });
  }
}

export default Web3StorageApi;

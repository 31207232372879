import {MdDelete} from "react-icons/md";
import getUUID from "../../utils/helpers/getUuid";
import {IDocument} from "../../utils/interfaces/documents";
import {useConfirmationDialogState} from "../../store/confirmationDialogState";

function DeleteFileButton({isAdmin, document}: {isAdmin: boolean; document: IDocument}) {
  const confirmationDialogState = useConfirmationDialogState();

  if (isAdmin) {
    return (
      <button
        type="button"
        className="text-blue-700 border border-blue-700 hover:bg-blue-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center items-center inline-flex ml-2"
        onClick={() =>
          confirmationDialogState.show({
            message: `Delete "${document.name}" folder will delete all files in it.`,
            docToUpdate: document,
          })
        }
        key={getUUID()}
      >
        {<MdDelete className="w-5 h-5" />}
      </button>
    );
  }

  return null;
}

export default DeleteFileButton;

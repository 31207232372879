import { useState, useEffect } from 'react';

import { auth, db } from '../firebase';
import { User } from 'firebase/auth';
import { collection, DocumentData, getDocs, query, where } from 'firebase/firestore';
import { IUsers } from '../utils/interfaces/users';

export default function useAuthListener() {
  const [user, setUser] = useState<{
    user: User | null
    isAdmin: boolean
  } | null>({
    user: null,
    isAdmin: false
  });

  useEffect(() => {
    const listener = auth.onAuthStateChanged(async (authUser) => {
      if (authUser) {
        const docs = await getDocs(query(collection(db, "users"), where("email", "==", authUser.email)));
        let user: Partial<DocumentData | IUsers> = {};
        docs.forEach(doc => user = doc.data())
        localStorage.setItem('authUser', JSON.stringify(authUser));
        setUser({user: authUser, isAdmin: user.isAdmin});
      } else {
        localStorage.removeItem('authUser');
        setUser(null);
      }
    });

    return () => listener?.();
  }, []);

  return { user };
}
import React from "react";

function TableSkeleton() {
  return (
    <table className="w-full text-sm text-left text-gray-500">
      <thead className="text-xs text-gray-700 uppercase bg-gray-50">
        <tr>
          <th scope="col" className="px-6 py-3">
            Document Type
          </th>
          <th scope="col" className="px-6 py-3">
            Name
          </th>
        </tr>
      </thead>
      <tbody>
        <tr className="bg-white border-b">
          <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
            <div role="status" className="max-w-sm animate-pulse">
              <div className="h-2.5 bg-gray-200 rounded-full w-5 mb-4"></div>
            </div>
          </th>
          <td className="px-6 py-4">
            <div role="status" className="max-w-sm animate-pulse">
              <div className="h-2.5 bg-gray-200 rounded-full w-48 mb-4"></div>
            </div>
          </td>
        </tr>
        <tr className="bg-white border-b">
          <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
            <div role="status" className="max-w-sm animate-pulse">
              <div className="h-2.5 bg-gray-200 rounded-full w-5 mb-4"></div>
            </div>
          </th>
          <td className="px-6 py-4">
            <div role="status" className="max-w-sm animate-pulse">
              <div className="h-2.5 bg-gray-200 rounded-full w-48 mb-4"></div>
            </div>
          </td>
        </tr>
        <tr className="bg-white border-b">
          <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
            <div role="status" className="max-w-sm animate-pulse">
              <div className="h-2.5 bg-gray-200 rounded-full w-5 mb-4"></div>
            </div>
          </th>
          <td className="px-6 py-4">
            <div role="status" className="max-w-sm animate-pulse">
              <div className="h-2.5 bg-gray-200 rounded-full w-48 mb-4"></div>
            </div>
          </td>
        </tr>
        <tr className="bg-white border-b">
          <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
            <div role="status" className="max-w-sm animate-pulse">
              <div className="h-2.5 bg-gray-200 rounded-full w-5 mb-4"></div>
            </div>
          </th>
          <td className="px-6 py-4">
            <div role="status" className="max-w-sm animate-pulse">
              <div className="h-2.5 bg-gray-200 rounded-full w-48 mb-4"></div>
            </div>
          </td>
        </tr>
        <tr className="bg-white border-b">
          <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
            <div role="status" className="max-w-sm animate-pulse">
              <div className="h-2.5 bg-gray-200 rounded-full w-5 mb-4"></div>
            </div>
          </th>
          <td className="px-6 py-4">
            <div role="status" className="max-w-sm animate-pulse">
              <div className="h-2.5 bg-gray-200 rounded-full w-48 mb-4"></div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default TableSkeleton;

import * as yup from "yup";

export const LoginFormSchema = yup
  .object({
    email: yup.string().email("Please enter a valida email.").required("Please enter your email."),
    password: yup.string().required("Please enter your password."),
  })
  .required();
export type LoginFormData = yup.InferType<typeof LoginFormSchema>;

export const SignupFormSchema = yup
  .object({
    email: yup.string().email("Please enter a valid email.").required("Please enter your email."),
    password: yup.string().required("Please enter your password."),
    confirmPassword: yup.string().required("Please confirm your password."),
  })
  .required();
export type SignupFormData = yup.InferType<typeof SignupFormSchema>;

export const ImageUploadSchema = yup.object({
  files: yup.mixed()
})
export type ImageUploadData = yup.InferType<typeof ImageUploadSchema>
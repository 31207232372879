import {MdClose} from "react-icons/md";
import React, {useEffect, useRef, useState} from "react";
import CircularLoader from "./circular-loader";
import {IDocument} from "../utils/interfaces/documents";
import {FileTypes} from "../utils/enums/file-types.enum";
import {useDialogPopupState} from "../store/dialogPopupState";

function InteractiveDialog({
  onSubmit,
  isLoading = false,
  onUpdate,
}: {
  onSubmit: (value: string) => void;
  isLoading: boolean;
  onUpdate?: (value: string, docToUpdate: IDocument) => void;
}) {
  const inputRef = useRef<HTMLInputElement>(null);
  const dialogPopupState = useDialogPopupState();

  const [extName, setExtName] = useState<string | null>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
      if (dialogPopupState.docToUpdate) {
        if (dialogPopupState.docToUpdate.type === FileTypes.folder) {
          inputRef.current.value = dialogPopupState.docToUpdate.name;
        } else {
          const fileExt = dialogPopupState.docToUpdate.originalName.split(".").slice(-1).join("");
          const fileName = dialogPopupState.docToUpdate.originalName.split(".").slice(0, -1).join(".");
          inputRef.current.value = fileName;
          setExtName(fileExt);
        }
      }
    }
  }, [dialogPopupState.docToUpdate]);

  function close() {
    if (inputRef.current) inputRef.current.value = "";
    dialogPopupState.hide();
  }

  return (
    <React.Fragment>
      <div className="bg-black/20 fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] md:h-full"></div>
      <div className="fixed w-full h-full max-w-md md:h-auto top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50">
        <div className="relative bg-white rounded-lg shadow">
          <div className="flex items-center justify-between p-5 border-b rounded-t">
            <h3 className="text-xl font-medium text-gray-900">{dialogPopupState.isUpdateMode ? "Rename" : "New folder"}</h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
              data-modal-hide="small-modal"
              onClick={close}
            >
              <MdClose className="w-5 h-5" />
              <span className="sr-only">Close modal</span>
            </button>
          </div>

          <div className="p-6 space-y-6">
            <input
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
              placeholder={dialogPopupState.isUpdateMode ? "Rename" : "New folder name"}
              ref={inputRef}
            />
          </div>

          <div className="flex justify-end items-center p-6 space-x-2 border-t border-gray-200 rounded-b">
            <button
              data-modal-hide="small-modal"
              type="button"
              className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10"
              onClick={close}
            >
              Cancel
            </button>

            {dialogPopupState.isUpdateMode ? (
              <button
                data-modal-hide="small-modal"
                type="button"
                className="text-white bg-red font-medium rounded-lg text-sm px-5 py-2.5 text-center flex flex-row items-center justify-between"
                onClick={() => {
                  let newName = inputRef?.current?.value!;
                  if (dialogPopupState.docToUpdate?.type !== FileTypes.folder) newName += `.${extName}`;
                  onUpdate!(`${newName}`, dialogPopupState.docToUpdate!);
                }}
              >
                {isLoading && <CircularLoader />}
                Rename
              </button>
            ) : (
              <button
                data-modal-hide="small-modal"
                type="button"
                className="text-white bg-red font-medium rounded-lg text-sm px-5 py-2.5 text-center flex flex-row items-center justify-between"
                onClick={() => onSubmit(inputRef?.current?.value!)}
              >
                {isLoading && <CircularLoader />}
                Create
              </button>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default InteractiveDialog;

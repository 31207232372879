import {BrowserRouter, Route, Routes} from "react-router-dom";
import "./App.css";
import {HomeLayout} from "./layouts/home.layout";
import HomePage from "./pages/home";
import DocumentsPage from "./pages/documents";
import useAuthListener from "./hooks/useAuthListener";
import {UserContext} from "./contexts";
import AuthPage from "./pages/auth";

export const App = () => {
  const {user} = useAuthListener();

  return (
    <UserContext.Provider value={{user}}>
      <BrowserRouter>
        <Routes>
          <Route element={<HomeLayout />}>
            <Route path="/" element={<HomePage />} />
            <Route path="/admin" element={<AuthPage />} />
            <Route path="/documents" element={<DocumentsPage />} />
            <Route path="/documents/:documentId" element={<DocumentsPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </UserContext.Provider>
  );
};
